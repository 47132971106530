import React from "react";
import numeral from "numeral";
import useDeviceType from "../../hooks/useDeviceType";

const NoAccount = ({
  value,
  enterprise,
  ikname,
  inkindStateAppStoreUrl,
  inkindStatePlayStoreUrl,
  inkindStateDomain,
}) => {
  const QRCode = require(
    `../../../assets/images/enterprise/${enterprise}/icons/app-qr-code.svg`,
  );
  const phoneMock = require(
    `../../../assets/images/enterprise/${enterprise}/icons/newPhoneMock.png`,
  );
  const logo = require(
    `../../../assets/images/enterprise/${enterprise}/icons/logo.png`,
  );
  const dollarIcon = require(
    `../../../assets/images/enterprise/${enterprise}/icons/dollar-icon.svg`,
  );
  const guestGroup = require("../../../assets/images/icons/guestgroup.svg");
  const { isApple, isAndroid } = useDeviceType();
  const handleClick = () => window.open(inkindStateDomain);

  return (
    <div className="no-account">
      <div className="welcome-bonus-container">
        <div className="welcome-bonus-top">
          <p className="superheader">LAST STEP</p>
          <div className="offer-claim-header">Download the {ikname} app!</div>
        </div>
        <div className="welcome-bonus-restrictions">
          Your offer will be auto-applied when you use the {ikname} app to
          seamlessly pay your bill from your phone.
        </div>
        <div className="divider-new"></div>
      </div>

      <div className="unlocked-panel-container">
        <div className="unlocked-panel-top">
          <div className="phone-display" onClick={handleClick}>
            <div className="iphone-container">
              <div
                className="iphone-bg"
                style={{
                  backgroundImage: `url(${phoneMock})`,
                }}
              >
                <div className="unlocked-phone-container">
                  <img className="unlocked-offer-header" src={logo} />
                  <p className="unlocked-primary-header">
                    {numeral(value).format("$0[.]00")}
                  </p>
                  {<p className="unlocked-secondary-header">Added</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="unlocked-offer-claim-container code-main">
            <div className="download-links-desktop">
              <img className="qrcode" src={QRCode} onClick={handleClick} />
              <div className="download-app-btns">
                <a
                  className="download-button apple-button"
                  href={inkindStateAppStoreUrl}
                  target="_blank"
                  style={{ display: isAndroid ? "none" : "" }}
                ></a>
                <a
                  className="download-button google-button"
                  href={inkindStatePlayStoreUrl}
                  target="_blank"
                  style={{ display: isApple ? "none" : "" }}
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div className="unlocked-panel-bottom" onClick={handleClick}>
          <img src={dollarIcon} alt="dollar icon" className="dollar-icon" />
          <p>Download the {ikname} app to pay</p>
        </div>
      </div>
      <section className="guests-paid">
        {" "}
        <img src={guestGroup} alt="guest avatars" />
        <span>
          <span className="bold-value">100k+</span> guests have paid with{" "}
          {ikname} this month
        </span>
      </section>
    </div>
  );
};

export default NoAccount;
