import React from "react";

const PartnerLogout = ({ handleLogOut }) => {
  return (
    <div className="email__review--subtext">
      <a
        className="email__review--subtext--link"
        href=""
        onClick={(e) => handleLogOut(e)}
      >
        Log out
      </a>
    </div>
  );
};

export default PartnerLogout;
