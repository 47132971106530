import React, { useState, useEffect } from "react";
import useFetch from "@lib/use-fetch";
import axios from "@lib/axios";
import Layout from "./layout";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ConditionalRender from "@/shared/conditional-render";
import CheckoutLogin from "./checkout-login";
import CheckoutForgotPassword from "./checkout-forgot-password";
import CheckoutAddCredit from "./checkout-add-credit";
import CheckoutRedeemCredit from "./checkout-redeem-credit";
import CheckoutFailed from "./checkout-failed";
import CheckoutError from "./checkout-error";
import CheckoutReview from "./checkout-review";
import Loader from "@/shared/loader";
import useCookie from "@lib/use-cookie";
import { getCardDetails } from "@lib/helpers";
import { InKindNetworkError } from "../../helpers/InKindNetworkError";

const LoaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 160px;

  h3 {
    font-size: 1.625rem;
    margin-top: 3.25rem;
  }
`;

const axiosHeaders = {
  headers: {
    Authorization: "",
  },
};

const fetchOptionsProject = {
  initialState: {},
  request: async (posSlug, location, axiosHeaders) => {
    if (posSlug && location && axiosHeaders) {
      try {
        const { data } = await axios.get(
          `/api/v4/external_locations/${posSlug}/${location}`,
          axiosHeaders,
        );
        return data.data;
      } catch (errors) {
        return { errors };
      }
    }
    return {};
  },
};

const fetchOptionsTicket = {
  initialState: {},
  request: async (posSlug, project, ticket, payAmount, axiosHeaders) => {
    if (posSlug && project && ticket && axiosHeaders) {
      try {
        const { data } = await axios.get(
          project.location_type === "Project"
            ? `/api/v4/projects/${project.project_id}/tickets/${ticket}?pos=${posSlug}`
            : `/api/v4/projects/${project.project_id}/tickets/${ticket}?pos=${posSlug}&location_id=${project?.location_id}`,
          {
            params: {
              pay_amount: payAmount ? payAmount : 0,
            },
            ...axiosHeaders,
          },
        );

        return data;
      } catch (errors) {
        return { errors };
      }
    }
    return {};
  },
};

let skipTipEntryStage = false;
let skipAddCreditStage = false;

const Checkout = ({
  posSlug,
  token,
  location,
  projectInit,
  ticket,
  tip,
  returnUrl,
  payAmount,
}) => {
  const [checkoutData, setCheckoutData] = useState("");
  const [cookie, updateCookie] = useCookie("userToken", false);
  const [stripePromise, setStripePromise] = useState(false);
  const [errorMsg, setErrorMsg] = useState("An unknown error has occurred.");
  const [loading, setLoading] = useState(true);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [userToken, setUserToken] = useState(
    token ?? (cookie !== "false" ? cookie : false) ?? false,
  );
  const [stage, setStage] = useState("login");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [defaultTip, setDefaultTip] = useState(tip);
  const [tipStorage, setTipStorage] = useState(null);
  const tipFieldsDisabled = Boolean(defaultTip && defaultTip < 0);
  const [showToast, setShowToast] = React.useState(false);
  const [increment, setIncrement] = useState(0);
  const billLoadError = (msg) => {
    setTimeout(() => {
      redirectToVendor(returnUrl);
    }, 3500);
    setLoading(false);
    return setErrorMsg(msg);
  };
  payAmount = payAmount ? Number(payAmount).toFixed(2) : false;

  let mockTicket = false;

  const {
    response: projectData,
    status: projectStatus,
    request: requestProjects,
    error: projectError,
  } = useFetch(fetchOptionsProject);

  const {
    response: ticketData,
    status: ticketStatus,
    request: requestTicket,
    error: ticketError,
  } = useFetch(fetchOptionsTicket);

  const logout = () => {
    setUserToken(null);
    updateCookie(false);
    setStage("login");
    setLoading(false);
  };

  const handleFailure = (error) => {
    if (error?.response?.status === 401) {
      logout();
    } else {
      setPaymentMethod(null);
      setIncrement(increment + 1);
      setErrorMsg(InKindNetworkError(error).message);
    }
  };

  const redirectToVendor = (urlOverride = null) => {
    if (!urlOverride || !returnUrl) return;
    const finalReturnUrl = urlOverride || returnUrl;
    return window.location.replace(finalReturnUrl);
  };

  const isCardNeeded = (values) => {
    const tip =
      !!values && !!values.tip
        ? Number(values.tip.replace(/[^0-9\.]+/g, ""))
        : 0;
    if (
      tip <= 0 &&
      defaultTip <= 0 &&
      ticketData.bill_house_account_amount < projectData.balance &&
      ticketData.bill_card_amount <= 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  // User token is available, fetch project
  useEffect(() => {
    (async () => {
      if (!userToken) return (axiosHeaders.headers["Authorization"] = "");
      setLoading(true);
      axiosHeaders.headers["Authorization"] = `Bearer ${userToken}`;
      requestProjects(posSlug, location, axiosHeaders);
    })();
  }, [userToken]);

  // project data is available, fetch ticket
  useEffect(() => {
    (async () => {
      if (!userToken || Object.keys(projectData).length < 1) {
        return setLoading(false);
      }

      if (projectData?.errors?.response?.status === 401) {
        logout();
        return;
      } else if (projectData?.errors) {
        setErrorMsg(
          projectData?.errors?.response?.data?.error ||
            projectData.errors.message,
        );
        setLoading(false);
        return setStage("error");
      }

      if (projectData?.stripe_public_key && !stripePromise) {
        setStripePromise(loadStripe(projectData.stripe_public_key));
      }

      if (
        userToken &&
        projectStatus === "resolved" &&
        Object.keys(projectData).length > 0 &&
        !projectData?.project_id
      ) {
        setErrorMsg(
          "Something went wrong while loading your bill. Please go back and try again.",
        );
        setLoading(false);
        return setStage("error");
      }

      if (projectData?.project_id) {
        return requestTicket(
          posSlug,
          projectData,
          ticket,
          payAmount,
          axiosHeaders,
        );
      }
    })();
  }, [posSlug, projectData]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [stage]);

  // ticket data is available, proceed to next step
  useEffect(() => {
    if (!userToken || Object.keys(ticketData).length < 1) return;

    if (ticketData?.errors) {
      billLoadError("We couldn't load your bill. Returning to order...");
      return setStage("error");
    }

    if (ticketData?.id) {
      if (Number(ticketData?.bill_amount) == 0)
        return redirectToVendor(returnUrl);

      const suggestedTip = mockTicket
        ? mockTicket.service_charges.find((sc) => sc.type === "suggested_tip")
        : ticketData?.service_charges.find((sc) => sc.type === "suggested_tip");
      const hasAutomaticGratuity = mockTicket
        ? mockTicket.service_charges.find((sc) => sc.type === "gratuity") ||
          false
        : ticketData?.service_charges.find((sc) => sc.type === "gratuity") ||
          false;
      const calculatedTip = tipFieldsDisabled
        ? 0
        : hasAutomaticGratuity
          ? Math.max(defaultTip - hasAutomaticGratuity.due, 0)
          : defaultTip;

      // If tip fields are disabled, or a default tip was passed in and the value is valid + above $0.50, skip the tip selection step:
      if (
        tipFieldsDisabled ||
        (calculatedTip && (calculatedTip > 0.5 || calculatedTip <= 0.0))
      ) {
        if (!tipFieldsDisabled) {
          const tipCents = Math.max(Math.round(calculatedTip * 100), 0);
          setTipStorage(tipCents);
        }
        skipTipEntryStage = true;
      }
      if (
        Number(ticketData?.bill_amount) <=
        Number(projectData?.balance) +
          (projectData?.offer?.claim_details?.balance || 0)
      ) {
        skipAddCreditStage = true;
      }

      setLoading(false);
      if (skipAddCreditStage) {
        if (tipFieldsDisabled) {
          return setStage("redeemCredit");
        } else if (!tipFieldsDisabled) {
          return setStage("review");
        }
      } else if (skipTipEntryStage) {
        return setStage("review");
      } else {
        return setStage("redeemCredit");
      }
    }
  }, [ticketData]);

  // Set loading status
  useEffect(() => {
    if (!userToken) return;

    if (
      !loading &&
      (ticketStatus === "pending" ||
        projectStatus === "pending" ||
        (projectInit?.project_id && !projectData?.project_id))
    ) {
      setLoading(true);
    }
  }, [ticketStatus, projectStatus, projectData, projectInit]);

  // Error status for data has been updated
  useEffect(() => {
    if (projectError || ticketError) {
      if (
        projectError?.response?.status === 401 ||
        ticketError?.response?.status === 401
      ) {
        logout();
        return;
      } else {
        setErrorMsg(projectError ? projectError : ticketError);
        setLoading(false);
        setStage("error");
      }
    }
  }, [ticketError, projectError]);

  useEffect(() => {
    (async () => {
      if (errorMsg !== "An unknown error has occurred." && !!errorMsg) {
        setStage("failed");
      }
    })();
  }, [errorMsg, increment]);

  // Defines the various stages of the app
  const stages = React.useMemo(() => {
    return {
      login: (
        <CheckoutLogin
          country={projectInit.country_code}
          onComplete={(user, shouldShowToast) => {
            setShowToast(shouldShowToast);
            setUserToken(user.token);
            setLoading(false);
          }}
        />
      ),
      forgotPassword: (
        <CheckoutForgotPassword onGoBack={() => setStage("login")} />
      ),
      addCredit: (
        <Elements stripe={stripePromise}>
          <CheckoutAddCredit
            offer={
              projectData?.offer &&
              projectData?.offer?.minimum_spend_amount <=
                (ticketData?.bill_amount || mockTicket?.bill_amount)
                ? projectData?.offer
                : null
            }
            setErrorMsg={setErrorMsg}
            axiosHeaders={axiosHeaders}
            posSlug={posSlug}
            location={location}
            ticket={mockTicket ? mockTicket : ticketData}
            project={projectData}
            setStage={setStage}
            setPaymentMethod={setPaymentMethod}
            onComplete={(giftPurchased = false) => {
              if (!giftPurchased) {
                return setStage(skipTipEntryStage ? "review" : "redeemCredit");
              }
              requestProjects(posSlug, location, axiosHeaders);
            }}
            onPaymentFailed={(error) => handleFailure(error)}
            isCardNeeded={isCardNeeded}
          />
        </Elements>
      ),
      redeemCredit: (
        <Elements stripe={stripePromise}>
          <CheckoutRedeemCredit
            setCheckoutData={setCheckoutData}
            axiosHeaders={axiosHeaders}
            location={location}
            ticket={mockTicket ? mockTicket : ticketData}
            project={projectData}
            defaultTip={defaultTip}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            tipStorage={tipStorage}
            setTipStorage={setTipStorage}
            tipFieldsDisabled={tipFieldsDisabled}
            isCardNeeded={isCardNeeded}
            onPaymentFailed={(error) => handleFailure(error)}
            offer={
              projectData?.offer &&
              projectData?.offer?.minimum_spend_amount <=
                (ticketData?.bill_amount || mockTicket?.bill_amount)
                ? projectData?.offer
                : null
            }
            onComplete={() => {
              setStage("review");
            }}
          />
        </Elements>
      ),
      review: (
        <Elements stripe={stripePromise}>
          <CheckoutReview
            checkoutData={checkoutData}
            setCheckoutData={setCheckoutData}
            offer={
              projectData?.offer &&
              projectData?.offer?.minimum_spend_amount <=
                (ticketData?.bill_amount || mockTicket?.bill_amount)
                ? projectData?.offer
                : null
            }
            axiosHeaders={axiosHeaders}
            earnBackAmount={Number(projectData?.sub_credit_back_percent)}
            posSlug={posSlug}
            location={location}
            ticket={mockTicket ? mockTicket : ticketData}
            project={projectData}
            defaultTip={defaultTip}
            payAmount={payAmount}
            returnUrl={returnUrl}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            tipStorage={tipStorage}
            setTipStorage={setTipStorage}
            tipFieldsDisabled={tipFieldsDisabled}
            skipTipEntryStage={skipTipEntryStage}
            isCardNeeded={isCardNeeded}
            onComplete={redirectToVendor}
            onPaymentAlreadyComplete={() => {
              return setTimeout(() => {
                redirectToVendor(returnUrl);
              }, 3000);
            }}
            redirectToVendor={redirectToVendor}
            setPaymentProcessing={setPaymentProcessing}
            onPaymentFailed={(error) => handleFailure(error)}
          />
        </Elements>
      ),
      failed: (
        <CheckoutFailed
          onGoBack={() => setStage("review")}
          errorMsg={errorMsg}
        />
      ),
      error: (
        <CheckoutError onGoBack={redirectToVendor} returnUrl={returnUrl}>
          {errorMsg}
        </CheckoutError>
      ),
    };
  }, [
    axiosHeaders,
    location,
    projectData,
    ticketData,
    mockTicket,
    defaultTip,
    payAmount,
    paymentMethod,
    tipStorage,
    tipFieldsDisabled,
    skipTipEntryStage,
    errorMsg,
  ]);

  return (
    <Layout
      stages={stages}
      stage={stage}
      displayJumpMenu={false}
      onStageChange={(stage) => setStage(stage)}
      projectInit={projectInit}
      project={projectData}
      returnUrl={returnUrl}
      onLogout={logout}
      userToken={userToken}
      skipTipEntryStage={skipTipEntryStage}
      backButtonAction={
        stage === "review" && !skipTipEntryStage
          ? () => setStage("redeemCredit")
          : Number(ticketData?.bill_amount) >
              Number(projectData?.balance) +
                (projectData?.offer?.claim_details?.balance || 0)
            ? () => setStage("redeemCredit")
            : () => {}
      }
      backButtonLabel={
        stage === "review" &&
        (!skipTipEntryStage ||
          Number(ticketData?.bill_amount) >
            Number(projectData?.balance) +
              (projectData?.offer?.claim_details?.balance || 0))
          ? "Back"
          : false
      }
      paymentProcessing={paymentProcessing}
      setShowToast={setShowToast}
      showToast={showToast}
      // debugging={[
      //   {
      //     label: "Location ID",
      //     value: location,
      //   },
      //   {
      //     label: "Ticket ID",
      //     value: ticket,
      //   },
      //   {
      //     label: "Tip",
      //     value: tip,
      //   },
      //   {
      //     label: "Return URL",
      //     value: returnUrl,
      //   },
      //   {
      //     label: "Pay Amount",
      //     value: payAmount,
      //   },
      // ]}
    >
      {loading && (
        <LoaderWrap>
          <Loader
            className={css`
              margin: 0 auto;
            `}
          />
          <h3>Getting your bill details</h3>
        </LoaderWrap>
      )}
      <span
        className={css`
          min-height: 100%;
          width: 100%;
        `}
        style={{
          display: !loading ? "block" : "none",
        }}
      >
        {stage in stages ? stages[stage] : stages.error}
      </span>
    </Layout>
  );
};

// Checkout.defaultProps = {
//   token: false,
//   posSlug: false,
//   location: false,
//   project: false,
//   ticket: false,
//   tip: false,
//   returnUrl: "",
//   payAmount: false,
// };

export default Checkout;
