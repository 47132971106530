import React from "react";
import { Provider } from "react-redux";
import fetch from "isomorphic-fetch";
import { OfferState } from "@/offer/App";
import numeral from "numeral";

const CodeBox = ({
  value,
  phoneNum,
  setDisplayState,
  setSendType,
  verifySmsCode,
  codeSubmitting,
  handleContactSubmit,
  showError,
  handleShowError,
  isPass,
  loading,
}) => {
  const [string, setString] = React.useState("");

  React.useEffect(() => {
    handleIndInputs();
  }, []);

  React.useEffect(() => {
    if (string.length === 6) {
      verifySmsCode(string);
    }
  }, [string]);

  function handleChangeNumber(e) {
    e.preventDefault();
    setSendType("phone");
    setDisplayState(2);
    handleShowError(false);
  }

  const handlePaste = (event) => {
    event.preventDefault();
    event.clipboardData.items[0].getAsString((text) => {
      text = text.substring(0, 6);
      let checkNum = Number(text);
      if (isNaN(checkNum)) {
        return;
      }
      const inputs = document.querySelectorAll("#otp input");
      for (let i = 0; i < inputs.length; i++) {
        if (typeof text[i] !== "undefined") {
          inputs[i].value = text[i];
        }
      }
      if (text.length < 6) {
        inputs[text.length - 1].select();
      }
      setString("");
      setString(text);
    });
  };

  function handleAutofill(e) {
    e.preventDefault();
  }

  function popuNext(el, data) {
    const inputs = document.querySelectorAll("#otp > *");
    el.value = data[0]; // Apply first item to first input
    data = data.substring(1); // remove the first char.
    if (el.nextElementSibling && data.length) {
      popuNext(el.nextElementSibling, data);
    } else {
      let names = [];
      for (let i = 0; i < inputs.length; i++) {
        names.push(inputs[i].value);
      }
      setString(names.join(""));
    }
  }

  function handleInput(e) {
    let data = e.data || e.target.value;
    if (!data) return;
    if (data.length <= 1) {
      return;
    } else {
      popuNext(e.target, data);
    }
  }

  function handleIndInputs() {
    const inputs = document.querySelectorAll("#otp > *");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("keyup", function (event) {
        let data = event.data || event.target.value;
        if (event.keyCode === 86 && event.metaKey === true) {
          return;
        }
        if (event.key === "ArrowRight") {
          this.nextElementSibling.select();
          return;
        }
        if (event.key === "ArrowLeft") {
          this.previousElementSibling.select();
          return;
        }
        if (event.key === "Backspace") {
          inputs[i].value = "";
          if (i !== 0) inputs[i - 1].focus();
        } else {
          if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (this.nextElementSibling) {
              this.nextElementSibling.select();
            }
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            event.preventDefault();
            return;
          } else if (this.nextElementSibling) {
            this.nextElementSibling.select();
          }
        }
        let names = [];
        for (let i = 0; i < inputs.length; i++) {
          names.push(inputs[i].value);
        }
        setString(names.join(""));
      });
    }
    const thisInput = inputs[0];
    thisInput.select();
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (string.length < 6) return;
    verifySmsCode(string);
    setString("");
  };

  return (
    <div className="claim-container code-main">
      <div className="big-header header">
        <p className="texted-info">
          Verify the code we texted <span>{phoneNum}</span>
        </p>
      </div>
      <form
        className="code-form"
        style={{ pointerEvents: !!codeSubmitting ? "none" : "" }}
        onSubmit={handleFormSubmit}
      >
        <div
          id="otp"
          className={
            !!isPass ? "code-container pass-code-container" : "code-container"
          }
          onPaste={(e) => handlePaste(e)}
        >
          <input
            name="code1"
            inputMode="numeric"
            inputtype="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            maxLength="6"
            id="first-input"
            onChange={(e) => handleAutofill(e)}
            onInput={(e) => handleInput(e)}
          />
          <input
            name="code2"
            inputMode="numeric"
            inputtype="numeric"
            pattern="[0-9]*"
            maxLength="1"
          />
          <input
            name="code3"
            inputMode="numeric"
            inputtype="numeric"
            pattern="[0-9]*"
            maxLength="1"
          />
          <input
            name="code4"
            inputMode="numeric"
            inputtype="numeric"
            pattern="[0-9]*"
            maxLength="1"
          />
          <input
            name="code5"
            inputMode="numeric"
            inputtype="numeric"
            pattern="[0-9]*"
            maxLength="1"
          />
          <input
            name="code6"
            inputMode="numeric"
            inputtype="numeric"
            pattern="[0-9]*"
            maxLength="1"
          />
        </div>
        <div
          className="error-message"
          style={{
            display: !showError ? "none" : "",
            color: "red",
            paddingTop: "12px",
          }}
        >
          Incorrect verification code. Please try again.
        </div>
        <div className="send-code">
          <a
            href="#"
            className={!!isPass ? "highlight-link-code" : "highlight-link-code"}
            onClick={(e) => handleChangeNumber(e)}
          >
            Change phone number
          </a>
          <a
            href="#"
            className="highlight-no-code"
            onClick={(e) => setDisplayState(OfferState.altVerification)}
          >
            Didn't receive code?
          </a>
        </div>
        <button className="offer-verify-button" type="submit">
          {loading ? (
            <div
              className="loading-container"
              style={{ display: loading === true ? "" : "none" }}
            >
              <div className="loading-screen">
                <div className="loading-ic"></div>
              </div>
            </div>
          ) : (
            "Verify"
          )}
        </button>
      </form>
    </div>
  );
};

export default CodeBox;
