import React from "react";
import ChangeRequest from "./ChangeRequest.jsx";
import EmailList from "./EmailList.jsx";
import ApprovedView from "./ApprovedView.jsx";
import PartnerLogin from "./PartnerLogin.jsx";
import PartnerLogout from "./PartnerLogout.jsx";
import PageUnavailable from "./PageUnavailable.jsx";
import ResetPw from "./ResetPw.jsx";
import ActivityHistory from "../campaigns/ActivityHistory.jsx";
import useCookie from "@lib/use-cookie";
import ikLogo from "../../../assets/images/icons/inKind_lg_dark.svg";
import ConfirmEmailToast from "../new-login-create-account/ConfirmEmailToast.jsx";

const App = () => {
  const [sentState, setSentState] = React.useState(false);
  const [approvedState, setApprovedState] = React.useState(false);
  const [logMessage, setLogMessage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [resetPassword, setResetPassword] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [finishedState, setFinishedState] = React.useState(false);
  const [response, setResponse] = React.useState(null);
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [token, updateToken] = useCookie("userToken", null);
  const [attachLength, setAttachLength] = React.useState(1);
  const [isSending, setIsSending] = React.useState(false);
  const [isApproved, setIsApproved] = React.useState(null);
  const [brandOwnersAll, setBrandOwnersAll] = React.useState([]);
  const [showToast, setShowToast] = React.useState(false);
  const [emailId, setEmailId] = React.useState(
    new URLSearchParams(window.location.search).get("em"),
  );
  const [silentStatus, setSilentStatus] = React.useState(false);

  const handleOwnerSetup = (brandOwners) => {
    const newOwnersArr = [];
    for (let i = 0; i < brandOwners.length; i++) {
      newOwnersArr.push(brandOwners[i].name);
    }
    setBrandOwnersAll(newOwnersArr);
  };

  React.useEffect(() => {
    if (approvedState === true) {
      setSentState(true);
    }
  }, [approvedState]);

  React.useEffect(() => {
    if (sentState === true) {
      setLogMessage("");
    }
  }, [sentState]);

  React.useEffect(() => {
    if (!!token) {
      const config = { method: "GET" };
      config.headers = new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      });

      fetch(
        "/api/v5/campaign_brands/" + window.location.pathname.split("/")[2],
        config,
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((response) => {
          if (response?.errors?.base?.length > 0) {
            setResponse("error");
          } else {
            if (
              new URLSearchParams(window.location.search).get("approve") ===
                "true" &&
              response?.campaignBrand?.status !== "approved" &&
              response?.approver === true
            ) {
              setApprovedState(true);
            }
            if (response.campaignBrand.status === "approved") {
              setFinishedState(true);
            }
            setResponse(response);
            handleOwnerSetup(response.brandOwners);
          }
          setLoggingIn(false);
        })
        .catch((err) => {
          setResponse("error");
          setLoggingIn(false);
        });
    }
  }, [token]);

  React.useEffect(() => {
    if (attachLength === 0) {
      setAttachLength(1);
    }
  }, [attachLength]);

  const handleLogOut = (e) => {
    e.preventDefault();
    updateToken(null);
  };

  return (
    <div className="email__review">
      {showToast ? <ConfirmEmailToast setShowToast={setShowToast} /> : null}
      {!!token ? (
        <div className="email__review--logout">
          <PartnerLogout handleLogOut={handleLogOut} />
        </div>
      ) : null}
      <header className="email__review--header">
        <img className="email__review--header--logo" src={ikLogo} />
        {!!response && response !== "error" ? (
          <>
            <div className="email__review--header--heading">+</div>
            <img
              className="email__review--header--logo"
              src={`https://res-5.cloudinary.com/${response?.cloudinary?.name}/image/upload/c_fill,w_150/${response?.brand?.branding?.logo.light_mode}`}
            />
            <div className="email__review--header--divider"></div>
          </>
        ) : null}
        <div className="email__review--header--text">
          <div className="email__review--header--heading">Email Review</div>
          {!!response && response !== "error" ? (
            <div className="email__review--header--subtext">
              {response?.brand?.name} - {response?.campaign?.name}
            </div>
          ) : null}
        </div>
      </header>
      {!token ? (
        <main className="email__review--main">
          <div className="email__review--container">
            <PartnerLogin
              setUser={setUser}
              updateToken={updateToken}
              token={token}
              setResetPassword={setResetPassword}
              setShowToast={setShowToast}
            />
          </div>
        </main>
      ) : (
        <main
          className="email__review--main"
          style={{
            display:
              new URLSearchParams(window.location.search).get("approve") ===
                "true" &&
              response?.approver === true &&
              !finishedState
                ? "none"
                : "",
          }}
        >
          {response === "error" ? (
            <>
              <PageUnavailable />
            </>
          ) : !response ? (
            <></>
          ) : (
            <>
              <div className="email__review--container">
                {!!finishedState ? (
                  <ApprovedView
                    setSentState={setSentState}
                    startDate={response?.campaign?.start_date}
                    sendTime={response?.campaign?.send_time}
                  />
                ) : null}
                <ChangeRequest
                  setSentState={setSentState}
                  logMessage={logMessage}
                  setLogMessage={setLogMessage}
                  images={images}
                  setImages={setImages}
                  cloudinary={response?.cloudinary}
                  attachLength={attachLength}
                  setAttachLength={setAttachLength}
                  isSending={isSending}
                  finishedState={finishedState}
                />
              </div>
              {!finishedState && !!response?.approver === true ? (
                <div className="email__review--subtext email__review--approve">
                  Don't need to request any edits?{" "}
                  <span
                    className="email__review--subtext--link"
                    href=""
                    onClick={() => setApprovedState(true)}
                  >
                    &nbsp;Approve all emails
                  </span>
                </div>
              ) : null}
              <div className="redesign-section">
                <div className="cmpgn-section">
                  <ActivityHistory
                    isPartnerPortal={true}
                    id={response?.campaignBrand?.id}
                    logMessage={logMessage}
                    setLogMessage={setLogMessage}
                    sentState={sentState}
                    setSentState={setSentState}
                    approvedState={approvedState}
                    images={images}
                    setImages={setImages}
                    chatMessages={response?.activityLog}
                    token={token}
                    setFinishedState={setFinishedState}
                    setAttachLength={setAttachLength}
                    isSending={isSending}
                    setIsSending={setIsSending}
                    brandOwners={brandOwnersAll}
                    setApprovedState={setApprovedState}
                    emailId={emailId}
                    setSilentStatus={setSilentStatus}
                  />
                </div>
              </div>
            </>
          )}
        </main>
      )}
    </div>
  );
};

export default App;
