import React from "react";

const BrandTile = ({ brand, cloudinaryPath }) => {
  return (
    <div
      className="location-brand"
      style={{
        backgroundImage: brand?.hero_image?.value
          ? `url(${
              cloudinaryPath.replace("-test", "") +
              "b_black,o_75,w_400,c_fit/" +
              brand?.hero_image?.value?.replace(".png", ".jpg")
            })`
          : null,
        backgroundColor: "#15171d",
      }}
    >
      {!!brand?.logo ? (
        <img
          src={
            cloudinaryPath.replace("-test", "") +
            "w_320,c_fit/" +
            brand.logo.dark_mode
          }
          alt={brand.name}
        />
      ) : (
        <span>{brand.name}</span>
      )}
    </div>
  );
};

export default BrandTile;
