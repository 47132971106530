import React from "react";

const getHighlightedText = (text, highlights) => {
  const lowerCaseHighlights = highlights.map((word) => word.toLowerCase());
  const regex = new RegExp(`(${lowerCaseHighlights.join("|")})`, "gi");
  const parts = text.split(regex);

  return parts.map((part, index) => {
    if (lowerCaseHighlights.includes(part.toLowerCase())) {
      // Highlighted text
      return (
        <span key={index} className="highlight">
          {part}
        </span>
      );
    } else {
      // Normal text
      return <span key={index}>{part}</span>;
    }
  });
};

const WelcomeBonus = ({
  title,
  value,
  description,
  displayState,
  restrictionText,
  restrictionHighlights,
}) => {
  return (
    <div className="welcome-bonus-container">
      <div className="welcome-bonus-top">
        <p className="superheader">{title}</p>
        <div className="offer-claim-header">
          {displayState !== 7
            ? `Claim your ${value}`
            : `Go spend your ${value}`}
        </div>
      </div>
      <div className="welcome-bonus-restrictions">
        {getHighlightedText(restrictionText, restrictionHighlights)}
      </div>
      <div className="divider-new"></div>
      {!!description.trim() && (
        <>
          <div className="offer-details">
            {description.split("\n").map((str, i) => (
              <p key={i}>{str}</p>
            ))}
          </div>
          <div className="divider-new"></div>
        </>
      )}
    </div>
  );
};

export default WelcomeBonus;
