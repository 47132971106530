import React from "react";
import poweredBy from "../../../../assets/images/checkout/inKind_Powered_By.png";
import { ChevronRightIcon, MinusIcon } from "@heroicons/react/20/solid";
import numeral from "numeral";
import axios from "axios";
import { useDebounce } from "use-debounce";
import CustomCurrencyInput from "./CustomCurrencyInput";
import Countdown from "react-countdown";
import { InKindNetworkError } from "../../../helpers/InKindNetworkError";

const InKindFFinner = ({ setFriendsAndFamily, second, tb, utm_values }) => {
  const isPass = window.location.host.split(".")[0] === "pass";
  const total = Date.parse(tb.promo_end_date) - Date.parse(new Date());
  let [loading, setLoading] = React.useState(false);
  let [currency, setCurrency] = React.useState(
    tb.open_amount_presets[1].toString(),
  );
  let [error, setError] = React.useState(null);
  let [totalBonus, setTotalBonus] = React.useState(0);
  let [toAdd, setToAdd] = React.useState(100);
  let [nextBonus, setNextBonus] = React.useState(20);
  let [showBubble, setShowBubble] = React.useState(false);
  let [curInput, setCurInput] = React.useState(null);
  let [hasCode, setHasCode] = React.useState(
    !!new URLSearchParams(window.location.search).get("code"),
  );

  window.addEventListener("pageshow", function (event) {
    if (event.persisted) {
      setLoading(false);
      setCurrency("");
    }
  });

  function handleSubmit() {
    if (currency === undefined) {
      setError("Invalid character");
      setCurrency("");
      return;
    } else if (Number(numeral(currency).format("00.00")) > 99999) {
      setError("Your amount is above the maximum account limit");
      setCurrency("");
      return;
    } else if (
      tb.open_amount_type === "flat_bonus_amount_per_spend" &&
      30 > Number(numeral(currency).format("00.00"))
    ) {
      setError("• Minimum account is $30");
    } else if (
      (tb.open_amount_type === "flat_bonus_percent" ||
        tb.open_amount_type === "flat_discount_percent") &&
      Number(numeral(currency).format("00.00")) < tb.open_amount_minimum
    ) {
      setError(
        `Minimum amount is $${Number(tb.open_amount_minimum).toLocaleString()}`,
      );
    } else {
      setLoading(true);
      const bodyFormData = new FormData();
      bodyFormData.append("amount", Number(numeral(currency).format("00.00")));
      const campaignCodeMatch = /[?&]code=([^&]*)/i.exec(
        window.location.search,
      );
      const campaignCode =
        campaignCodeMatch &&
        decodeURIComponent(campaignCodeMatch[1].replace(/\+/g, " "));
      if (campaignCode) {
        bodyFormData.append("code", campaignCode);
      }
      if (tb.promo_slug) {
        bodyFormData.append("promotion_slug", tb.promo_slug);
      }
      if (tb.inkind_cash) {
        axios
          .post("/inkind_cash/open_amount_offers", bodyFormData)
          .then(
            (resp) =>
              (window.location.href = !!isPass
                ? `/checkout?ico_id=${resp.data.inkind_cash_offer_id}${
                    utm_values ? `&${utm_values.substring(1)}` : ""
                  }&code=${tb.promo_slug}`
                : `/checkout?ico_id=${resp.data.inkind_cash_offer_id}${
                    utm_values ? `&${utm_values.substring(1)}` : ""
                  }`),
          )
          .catch((e) => {
            setLoading(false);
            alert(InKindNetworkError(e)?.message);
          });
      } else {
        axios
          .post("/gift_card_options", bodyFormData)
          .then(
            (resp) =>
              (window.location.href = `/checkout?gco_id=${resp.data.gco_id}${
                utm_values ? `&${utm_values.substring(1)}` : ""
              }`),
          )
          .catch((e) => {
            setLoading(false);
            alert(InKindNetworkError(e)?.message);
          });
      }
    }
  }

  function claim() {
    if (Number(numeral(currency).format("00.00")) < 99000)
      setCurrency(
        (currency) => Number(numeral(currency).format("00.00")) + toAdd,
      );
    else {
      setError("Maximum account credit reached");
      setShowBubble(false);
    }
  }

  function calc_total_bonus() {
    if (tb.open_amount_type === "flat_bonus_amount_per_spend") {
      let times_incentive = ~~(
        numeral(currency).format("00.00") / tb.open_amount_minimum
      );
      if (times_incentive < 1) {
        setTotalBonus(0);
      } else {
        setTotalBonus(times_incentive * tb.open_amount_incentive_amount);
      }
    } else {
      setTotalBonus(
        Number(numeral(currency).format("00.00")) *
          (tb.open_amount_percentage / 100),
      );
    }
  }

  function handleActive(e) {
    const selected = document.querySelectorAll(".inkind-tier-button.active");
    const currencyVal = Number(e.target.dataset.currency);
    setCurInput(e.target.parentElement.getElementsByTagName("input")[0]);
    for (let i = 0; i < selected.length; i++) {
      selected[i].classList.remove("active");
    }
    e.target.classList.add("active");
    setCurrency(currencyVal);
  }

  function calc_next_incentive() {
    if (tb.open_amount_type === "flat_bonus_amount_per_spend") {
      let times_incentive =
        ~~(numeral(currency).format("00.00") / tb.open_amount_minimum) + 1;
      let next_amount = times_incentive * tb.open_amount_minimum;
      let to_add = next_amount - numeral(currency).format("00.00");
      let next_bonus = times_incentive * tb.open_amount_incentive_amount;
      setToAdd(to_add || 100);
      setNextBonus(next_bonus);
    }
  }
  const [value] = useDebounce(showBubble, 400);

  React.useEffect(() => {
    calc_total_bonus();
    if (tb.open_amount_type === "flat_bonus_amount_per_spend") {
      calc_next_incentive();
    }
    if (currency == 0.0) {
      setCurrency("");
      setShowBubble(false);
    }
    if (currency) {
      setShowBubble(true);
    }
  }, [currency]);

  React.useEffect(() => {
    if (!!curInput) {
      curInput.focus();
    }
  }, [error]);

  const openAmountList = tb.open_amount_presets.map((amount, idx) => (
    <button
      data-currency={amount}
      onClick={(e) => handleActive(e)}
      className={`inkind-tier-button ${idx === 1 ? "active" : ""}`}
    >
      {numeral(Number(numeral(amount).format("00"))).format("$0,0")}
    </button>
  ));

  return (
    <>
      <h2 className="head-extra-bold panel-h">
        {second
          ? tb.second_offer_headline
          : !!isPass
            ? tb.promo_open_amount_cta
            : tb.open_amount_tier_modal_title}
      </h2>
      <div className="inkind-cash-description">
        {!!tb?.tier_modal_description?.length > 0 ? (
          <span className="regular-font">{tb.tier_modal_description}</span>
        ) : tb.open_amount_type === "flat_bonus_amount_per_spend" ? (
          <span className="regular-font">
            <span className="font-bold">
              Get ${tb.open_amount_incentive_amount}
            </span>{" "}
            for every{" "}
            <span className="font-bold">${tb.open_amount_minimum}</span> you
            gift.
          </span>
        ) : tb.open_amount_type === "flat_discount_amount_per_spend" ? (
          <span className="regular-font">
            <span className="font-bold">
              Take ${tb.open_amount_incentive_amount} off
            </span>{" "}
            for every{" "}
            <span className="font-bold">${tb.open_amount_minimum}</span> you
            spend.
          </span>
        ) : !!tb.promo_show_countdown ? (
          <span className="regular-font">
            Bonus ends in{" "}
            <Countdown
              zeroPadTime={2}
              daysInHours={true}
              date={Date.now() + total}
            />
          </span>
        ) : (
          <span className="regular-font">
            Pick any amount to get {tb.open_amount_percentage}%{" "}
            {tb.open_amount_type === "flat_bonus_percent" ? "extra:" : "off:"}
          </span>
        )}
      </div>
      <div className={second ? "second-offer-wrapper" : "pannel-inner-wrapper"}>
        <div className="panel-options-wrapper lefter ff">
          <div className="open-amount-col ff regular-font">
            {tb.open_amount_type === "flat_bonus_amount_per_spend" ? (
              <>
                <span className="font-bold">Purchase Amount</span>
                <div className="input-error-col">
                  <CustomCurrencyInput
                    currency={currency}
                    setCurrency={setCurrency}
                    setError={setError}
                    handleSubmit={handleSubmit}
                    error={error}
                    setCurInput={setCurInput}
                    buttonInput={false}
                  />
                  {error && <span className="error-msg">{error}</span>}
                </div>
                {value && currency ? (
                  <>
                    <div className="quote-col ff">
                      <div className="ff-popover ff-popover-triangle">
                        <div className="ff-talktext">
                          <div className="seperate">
                            <span className="smaller-text">
                              Adding{" "}
                              <span className="font-bold">
                                {numeral(toAdd).format("$0,0[.]00")}
                              </span>{" "}
                              more unlocks a{" "}
                              <span className="font-bold">
                                {numeral(nextBonus).format("$0,0")} bonus
                              </span>
                            </span>
                            <button
                              onClick={claim}
                              className="see-tiers smaller-text"
                            >
                              Claim
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="empty-bubble" />
                  </>
                )}
              </>
            ) : (
              <>
                <div className="purchase-buttons-wrapper">
                  {openAmountList}
                  <div className="input-container">
                    <CustomCurrencyInput
                      currency={currency}
                      setCurrency={setCurrency}
                      setError={setError}
                      handleSubmit={handleSubmit}
                      error={error}
                      setCurInput={setCurInput}
                      buttonInput={true}
                    />
                    <label className="custom-label" htmlFor="ff-input">
                      Custom
                    </label>
                  </div>
                </div>

                <div className="input-error-col">
                  {error && <span className="error-msg">{error}</span>}
                </div>
              </>
            )}
            <div
              style={{
                display: "flex",
                gap: "12px",
                flexDirection:
                  tb.open_amount_type === "flat_discount_percent"
                    ? "column-reverse"
                    : "column",
              }}
            >
              <div className="order-details">
                {tb.open_amount_type === "flat_bonus_amount_per_spend" ? (
                  <div className="keep-yourself">
                    <span>Bonus Amount</span>
                    <span className="keep-yourself-text">
                      To add to your gift or keep for yourself
                    </span>
                  </div>
                ) : tb.open_amount_type === "flat_bonus_percent" ? (
                  <div className="keep-yourself">
                    <span>
                      {!!isPass
                        ? tb.promo_open_amount_bonus_label
                        : "Bonus Amount"}
                    </span>
                    {tb.inkind_cash && (
                      <span className="keep-yourself-text">
                        {!isPass
                          ? "To add to your gift or keep for yourself"
                          : ""}
                      </span>
                    )}
                  </div>
                ) : (
                  <span>
                    {!!isPass
                      ? tb.open_amount_percentage +
                        "% " +
                        tb.promo_open_amount_bonus_label
                      : "Bonus Amount"}
                  </span>
                )}
                <span className="green-bonus">
                  {tb.open_amount_type === "flat_discount_percent" ? "-" : "+"}{" "}
                  {numeral(totalBonus).format("$0,0.00")}
                </span>
              </div>
              <div className="order-details ha">
                <span>Total Value</span>
                <span>
                  {tb.open_amount_type === "flat_discount_percent"
                    ? numeral(Number(numeral(currency).format("00.00"))).format(
                        "$0,0.00",
                      )
                    : numeral(
                        Number(numeral(currency).format("00.00")) + totalBonus,
                      ).format("$0,0.00")}
                </span>
              </div>
            </div>
            <div className="button-col">
              <button
                className={`inkind-pay-button ${
                  loading || !currency || error ? "processing" : "actionable"
                }`}
                disabled={loading || !currency || error}
                onClick={handleSubmit}
              >
                {!loading ? (
                  <>
                    {!isPass && !!tb.inkind_cash ? "Gift " : "Pay "}
                    {currency &&
                      currency != (undefined || 0) &&
                      tb.open_amount_type != "flat_discount_percent" &&
                      numeral(currency).format("$0,0[.]00")}
                    {currency &&
                      currency != (undefined || 0) &&
                      tb.open_amount_type === "flat_discount_percent" &&
                      numeral(
                        Number(numeral(currency).format("00.00")) - totalBonus,
                      ).format("$0,0[.]00")}
                    {!currency && numeral(0).format("$0,0[.]00")}
                    {(!!tb.inkind_cash && !isPass) || !!hasCode ? (
                      <>
                        , Get {numeral(totalBonus).format("$0,0[.]00") ?? "$0"}
                      </>
                    ) : (
                      <>
                        , Get{" "}
                        {numeral(
                          Number(
                            typeof currency === "string"
                              ? currency
                                  .replace("$", "")
                                  .replace(/[^0-9\.]+/g, "")
                              : !!currency
                                ? currency
                                : 0,
                          ) +
                            Number(
                              tb.open_amount_type === "flat_discount_percent"
                                ? 0
                                : totalBonus,
                            ),
                        ).format("$0,0[.]00")}
                      </>
                    )}

                    <div className="button-chevron">
                      <ChevronRightIcon className="chevron" />
                    </div>
                  </>
                ) : (
                  <MinusIcon className="spinner" />
                )}
              </button>
              {tb.inkind_cash && !isPass ? (
                <span className="under-text">
                  Gifting for business?{" "}
                  <a
                    href={
                      tb.sos
                        ? window.location.origin + "/business"
                        : window.location.origin.replace("/give", "/business") +
                          "/gifting#calculator-section"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="see-tiers "
                  >
                    Explore Business Accounts
                  </a>
                </span>
              ) : !isPass && !hasCode ? (
                <span className="under-text">
                  Want a larger free bonus?{" "}
                  <button
                    className="see-tiers "
                    onClick={() => setFriendsAndFamily(false)}
                  >
                    Explore Tier Packages
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {tb.fine_print && <span className="under-text">{tb.fine_print}</span>}

        {!isPass && (
          <img src={poweredBy} alt="powered by inKind" className="powered-by" />
        )}
      </div>
    </>
  );
};

export default InKindFFinner;
