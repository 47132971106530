import React from "react";
import NewLoginCreateAccount from "../new-login-create-account/NewLoginCreateAccount.jsx";

const PartnerLogin = ({ setUser, updateToken, setShowToast }) => {
  const [signInError, setSignInError] = React.useState(null);

  const onComplete = (user, shouldShowToast) => {
    setShowToast(shouldShowToast);
    updateToken(user.token, 365);
    setUser(user);
  };

  return (
    <div className="email__review__login--section">
      <div className="email__review__login--content">
        <NewLoginCreateAccount
          setUser={onComplete}
          siteArea={"partner_email_approval"}
        />
      </div>
    </div>
  );
};

export default PartnerLogin;
